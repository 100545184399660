import styled, { useTheme } from "styled-components";

const PositLogo = () => {
  return (
    <svg
      id="main-logo-arrows"
      viewBox="0 0 80 75"
      width="60"
      role="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path d="m43.7299 37.1244 16.4187-7.0641-3.6607-1.6333-16.4882 7.0942-3.7302 1.6032 3.7302 1.6032 16.6668 7.1643 3.6607-1.6332z"></path>
        <path d="m77.0739 54.6693v15.7716l-33.7502-15.3708-3.5913 1.6032 40.2681 18.3267v-22.2846l-15.9922-6.8737-3.6607 1.6332z"></path>
        <path d="m79.9983 0-40.0003 17.8457 3.6112 1.6132 33.4625-14.92984v15.05014l-16.9049 7.2645 3.6607 1.6333 16.1708-6.954z"></path>
        <path d="m19.623 44.2785-11.43859-5.1503v-3.8577l11.66669-5.2104 3.6608-1.6333 16.4882-7.3547-3.6112-1.6132-16.5576 7.3847-3.6607 1.6333-10.91279 4.8697v7.7054l10.70439 4.7996 3.6211 1.6534 16.5675 7.535 3.5913-1.5932-16.4981-7.515z"></path>
        <path d="m19.623 44.2786 3.6211 1.6533 16.756-7.2044-3.7301-1.6032z"></path>
        <path d="m23.5123 28.4268-3.6607 1.6332 16.4187 7.0642 3.7302-1.6033z"></path>
        <path d="m63.8297 28.477-3.6607-1.6333-16.5577-7.3848-3.6111-1.6132-40.0002-17.8457v21.523l16.1707 6.954 3.6608-1.6333-16.91482-7.2645v-15.05014l33.47242 14.92984 3.6111 1.6132 16.4882 7.3548 3.6607 1.6332 11.6667 5.2104v3.8578l-11.4881 5.1302-3.6607 1.6333-16.9247 7.5551-3.5913 1.5932-33.23432 14.8296v-15.2004l16.66672-7.1643-3.621-1.6533-15.9624 6.8637v21.6834l39.7323-17.7255 3.5913-1.6032 17.0239-7.5952 3.6608-1.6332 10.7242-4.7896v-7.7054z"></path>
      </g>
    </svg>
  );
};

const StyledPositLogoHero = styled.svg`
    margin: 10px;
`

const PositLogoHero = () => {
  const theme = useTheme();
  return (
    <StyledPositLogoHero
      id="main-logo-arrows"
      viewBox="0 0 80 75"
      width="30"
      role="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={theme.primary}>
        <path d="m43.7299 37.1244 16.4187-7.0641-3.6607-1.6333-16.4882 7.0942-3.7302 1.6032 3.7302 1.6032 16.6668 7.1643 3.6607-1.6332z"></path>
        <path d="m77.0739 54.6693v15.7716l-33.7502-15.3708-3.5913 1.6032 40.2681 18.3267v-22.2846l-15.9922-6.8737-3.6607 1.6332z"></path>
        <path d="m79.9983 0-40.0003 17.8457 3.6112 1.6132 33.4625-14.92984v15.05014l-16.9049 7.2645 3.6607 1.6333 16.1708-6.954z"></path>
        <path d="m19.623 44.2785-11.43859-5.1503v-3.8577l11.66669-5.2104 3.6608-1.6333 16.4882-7.3547-3.6112-1.6132-16.5576 7.3847-3.6607 1.6333-10.91279 4.8697v7.7054l10.70439 4.7996 3.6211 1.6534 16.5675 7.535 3.5913-1.5932-16.4981-7.515z"></path>
        <path d="m19.623 44.2786 3.6211 1.6533 16.756-7.2044-3.7301-1.6032z"></path>
        <path d="m23.5123 28.4268-3.6607 1.6332 16.4187 7.0642 3.7302-1.6033z"></path>
        <path d="m63.8297 28.477-3.6607-1.6333-16.5577-7.3848-3.6111-1.6132-40.0002-17.8457v21.523l16.1707 6.954 3.6608-1.6333-16.91482-7.2645v-15.05014l33.47242 14.92984 3.6111 1.6132 16.4882 7.3548 3.6607 1.6332 11.6667 5.2104v3.8578l-11.4881 5.1302-3.6607 1.6333-16.9247 7.5551-3.5913 1.5932-33.23432 14.8296v-15.2004l16.66672-7.1643-3.621-1.6533-15.9624 6.8637v21.6834l39.7323-17.7255 3.5913-1.6032 17.0239-7.5952 3.6608-1.6332 10.7242-4.7896v-7.7054z"></path>
      </g>
    </StyledPositLogoHero>
  );
};

export { PositLogo, PositLogoHero };
